<script>
import { mapState } from "vuex";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { CountTo } from "vue3-count-to";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";

// import Revenue from "./revenue";
// import SalesLocation from "./sales-location";

import SystemServices from "./../../services/SystemServices";

function getChartColorsArray(colors) {
  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(
        newValue
      );
      if (color) {
        color = color.replace(" ", "");
        return color;
      } else return newValue;
    } else {
      var val = value.split(",");
      if (val.length == 2) {
        var rgbaColor = getComputedStyle(
          document.documentElement
        ).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
}

export default {
  components: {
    CountTo,
    Layout,
    PageHeader,
    flatPickr,
    // Revenue,
    // SalesLocation,
  },
  data() {
    return {
      dashboardData: {},
      title: "Dashboard",
      items: [
        {
          text: "Dashboards",
          href: "/",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      date: "2022-01-01 to 2022-01-31",
      config: {
        mode: "range",
      },
      chartOptions: {
        labels: ["Direct", "Social", "Email", "Other", "Referrals"],
        chart: {
          height: 333,
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        stroke: {
          show: false,
        },
        dataLabels: {
          dropShadow: {
            enabled: false,
          },
        },
        colors: getChartColorsArray(
          '["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]'
        ),
      },
    };
  },
  computed: {
    ...mapState("auth", {
      currentUser: (state) => state.currentUser,
    }),
  },
  mounted() {
    this.fetchDashboard();
  },
  methods: {
    async fetchDashboard() {
      await SystemServices.fetchDashboard().then((res) => {
        this.dashboardData = res.data;
      });
    },
    rightcolumn() {
      if (
        document
          .querySelector(".layout-rightside-col")
          .classList.contains("d-none")
      ) {
        document
          .querySelector(".layout-rightside-col")
          .classList.remove("d-none");
      } else {
        document.querySelector(".layout-rightside-col").classList.add("d-none");
      }
    },
  },
};
</script>

<template>
  <Layout v-if="dashboardData.kpis">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col">
        <div class="h-100">
          <div class="row mb-3 pb-1">
            <div class="col-12">
              <div class="d-flex align-items-lg-center flex-lg-row flex-column">
                <div class="flex-grow-1">
                  <h4 class="fs-16 mb-1">
                    Bienvenue, {{ currentUser.username }} !
                  </h4>
                  <p class="text-muted mb-0">
                    Ici vous pouvez avoir une vue globale et gérer votre
                    plateforme
                  </p>
                </div>
                <div class="mt-3 mt-lg-0">
                  <form action="javascript:void(0);">
                    <div v-if="false" class="row g-3 mb-0 align-items-center">
                      <div class="col-sm-auto">
                        <div class="input-group">
                          <flat-pickr
                            v-model="date"
                            :config="config"
                            class="form-control border-0 dash-filter-picker shadow"
                          ></flat-pickr>

                          <div
                            class="input-group-text bg-primary border-primary text-white"
                          >
                            <i class="ri-calendar-2-line"></i>
                          </div>
                        </div>
                      </div>

                      <div class="col-auto">
                        <button
                          type="button"
                          class="btn btn-soft-success shadow-none"
                        >
                          <i class="ri-add-circle-line align-middle me-1"></i>
                          Valider
                        </button>
                      </div>
                    </div>
                    <!--end row-->
                  </form>
                </div>
              </div>
              <!-- end card header -->
            </div>
            <!--end col-->
          </div>
          <!--end row-->

          <div class="row">
            <div class="col-xl-3 col-md-6">
              <!-- card -->
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p
                        class="text-uppercase fw-medium text-muted text-truncate mb-0"
                      >
                        Montant encaissé
                      </p>
                    </div>
                    <div v-if="false" class="flex-shrink-0">
                      <h5 class="text-muted fs-14 mb-0">+0.00 %</h5>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-end justify-content-between mt-4"
                  >
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        <count-to
                          :startVal="0"
                          :endVal="dashboardData.kpis.notchpay_balance"
                          :duration="3000"
                        ></count-to>
                      </h4>
                      <a href="" class="text-decoration-underline">
                        Voir les transactions
                      </a>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-danger rounded fs-3">
                        <i class="bx bx-wallet"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->

            <div class="col-xl-3 col-md-6">
              <!-- card -->
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p
                        class="text-uppercase fw-medium text-muted text-truncate mb-0"
                      >
                        Les visites
                      </p>
                    </div>
                    <div v-if="false" class="flex-shrink-0">
                      <h5 class="text-danger fs-14 mb-0">
                        <i
                          class="ri-arrow-right-down-line fs-13 align-middle"
                        ></i>
                        -3.57 %
                      </h5>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-end justify-content-between mt-4"
                  >
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        <count-to
                          :startVal="0"
                          :endVal="dashboardData.kpis.visits"
                          :duration="3000"
                        ></count-to>
                      </h4>
                      <a href="" class="text-decoration-underline">
                        Voir les visites
                      </a>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-info rounded fs-3">
                        <i class="bx bx-shopping-bag"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->

            <div class="col-xl-3 col-md-6">
              <!-- card -->
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p
                        class="text-uppercase fw-medium text-muted text-truncate mb-0"
                      >
                        Les souscriptions
                      </p>
                    </div>
                    <div v-if="false" class="flex-shrink-0">
                      <h5 class="text-success fs-14 mb-0">
                        <i
                          class="ri-arrow-right-up-line fs-13 align-middle"
                        ></i>
                        +29.08 %
                      </h5>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-end justify-content-between mt-4"
                  >
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        <count-to
                          :startVal="0"
                          :endVal="dashboardData.kpis.subscriptions"
                          :duration="3000"
                        ></count-to>
                      </h4>
                      <a href="" class="text-decoration-underline">
                        Voir les souscriptions
                      </a>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-warning rounded fs-3">
                        <i class="bx bx-user-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->

            <div class="col-xl-3 col-md-6">
              <!-- card -->
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p
                        class="text-uppercase fw-medium text-muted text-truncate mb-0"
                      >
                        Les utilisateurs
                      </p>
                    </div>
                    <div v-if="false" class="flex-shrink-0">
                      <h5 class="text-success fs-14 mb-0">
                        <i
                          class="ri-arrow-right-up-line fs-13 align-middle"
                        ></i>
                        +29.08 %
                      </h5>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-end justify-content-between mt-4"
                  >
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        <count-to
                          :startVal="0"
                          :endVal="dashboardData.kpis.users"
                          :duration="3000"
                        ></count-to>
                      </h4>
                      <a href="" class="text-decoration-underline">
                        Voir les utilisateurs
                      </a>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-warning rounded fs-3">
                        <i class="bx bx-user-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->
          </div>
          <!-- end row-->

          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-header align-items-center d-flex">
                  <h4 class="card-title mb-0 flex-grow-1">
                    Dernières souscriptions
                  </h4>
                </div>
                <!-- end card header -->

                <div class="card-body">
                  <div class="table-responsive table-card">
                    <table
                      class="table table-hover table-centered align-middle table-nowrap mb-0"
                    >
                      <tbody>
                        <tr
                          v-for="(
                            subscription, index
                          ) of dashboardData.subscriptions.slice(0, 5)"
                          :key="index"
                        >
                          <td>
                            <h5 class="fs-14 my-1">
                              <router-link
                                :to="{
                                  name: 'subscriptions.details',
                                  params: { id: subscription.id },
                                }"
                                class="text-reset"
                              >
                                #{{ subscription.code }}
                              </router-link>
                            </h5>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div>
                                <h5 class="fs-14 my-1">
                                  <router-link
                                    :to="{
                                      name: 'subscriptions.details',
                                      params: { id: subscription.id },
                                    }"
                                    class="text-reset"
                                  >
                                    {{ subscription.user.username }}
                                  </router-link>
                                </h5>
                                <span class="text-muted">{{
                                  subscription.user.phone
                                }}</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <h5 class="fs-14 my-1 fw-normal">
                              {{ $formatPrice(subscription.total_amount) }}
                            </h5>
                            <small class="text-muted">Montant total</small>
                          </td>
                          <td>
                            <h5 class="fs-14 my-1 fw-normal">
                              {{
                                new Date(
                                  subscription.next_due_date
                                ).toLocaleDateString()
                              }}
                            </h5>
                            <small class="text-muted">Prochain paiement</small>
                          </td>
                          <!-- <td>
                            <div class="progress progress-sm animated-progess">
                              <div
                                class="progress-bar bg-success"
                                role="progressbar"
                                aria-valuenow="50"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                :style="`width: ${subscription.progress}%`"
                              ></div>
                            </div>
                            <span class="text-muted">Progress</span>
                          </td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <!-- .col-->
          </div>
          <!-- end row-->

          <div v-if="false" class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-header align-items-center d-flex">
                  <h4 class="card-title mb-0 flex-grow-1">
                    Dernières transactions
                  </h4>
                  <div class="flex-shrink-0"></div>
                </div>
                <!-- end card header -->

                <div class="card-body">
                  <div class="table-responsive table-card">
                    <table
                      class="table table-borderless table-centered align-middle table-nowrap mb-0"
                    >
                      <thead class="text-muted table-light">
                        <tr>
                          <th scope="col">Order ID</th>
                          <th scope="col">Customer</th>
                          <th scope="col">Product</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Vendor</th>
                          <th scope="col">Status</th>
                          <th scope="col">Rating</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <router-link
                              to="/ecommerce/order-details"
                              class="fw-medium link-primary"
                              >#VZ2112
                            </router-link>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="flex-shrink-0 me-2">
                                <img
                                  src="@/assets/images/users/avatar-1.jpg"
                                  alt=""
                                  class="avatar-xs rounded-circle shadow"
                                />
                              </div>
                              <div class="flex-grow-1">Alex Smith</div>
                            </div>
                          </td>
                          <td>Clothes</td>
                          <td>
                            <span class="text-success">$109.00</span>
                          </td>
                          <td>Zoetic Fashion</td>
                          <td>
                            <span class="badge badge-soft-success">Paid</span>
                          </td>
                          <td>
                            <h5 class="fs-14 fw-medium mb-0">
                              5.0<span class="text-muted fs-11 ms-1"
                                >(61 votes)</span
                              >
                            </h5>
                          </td>
                        </tr>
                        <!-- end tr -->
                        <tr>
                          <td>
                            <router-link
                              to="/ecommerce/order-details"
                              class="fw-medium link-primary"
                              >#VZ2111
                            </router-link>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="flex-shrink-0 me-2">
                                <img
                                  src="@/assets/images/users/avatar-2.jpg"
                                  alt=""
                                  class="avatar-xs rounded-circle shadow"
                                />
                              </div>
                              <div class="flex-grow-1">Jansh Brown</div>
                            </div>
                          </td>
                          <td>Kitchen Storage</td>
                          <td>
                            <span class="text-success">$149.00</span>
                          </td>
                          <td>Micro Design</td>
                          <td>
                            <span class="badge badge-soft-warning"
                              >Pending</span
                            >
                          </td>
                          <td>
                            <h5 class="fs-14 fw-medium mb-0">
                              4.5<span class="text-muted fs-11 ms-1"
                                >(61 votes)</span
                              >
                            </h5>
                          </td>
                        </tr>
                        <!-- end tr -->
                        <tr>
                          <td>
                            <router-link
                              to="/ecommerce/order-details"
                              class="fw-medium link-primary"
                              >#VZ2109
                            </router-link>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="flex-shrink-0 me-2">
                                <img
                                  src="@/assets/images/users/avatar-3.jpg"
                                  alt=""
                                  class="avatar-xs rounded-circle shadow"
                                />
                              </div>
                              <div class="flex-grow-1">Ayaan Bowen</div>
                            </div>
                          </td>
                          <td>Bike Accessories</td>
                          <td>
                            <span class="text-success">$215.00</span>
                          </td>
                          <td>Nesta Technologies</td>
                          <td>
                            <span class="badge badge-soft-success">Paid</span>
                          </td>
                          <td>
                            <h5 class="fs-14 fw-medium mb-0">
                              4.9<span class="text-muted fs-11 ms-1"
                                >(89 votes)</span
                              >
                            </h5>
                          </td>
                        </tr>
                        <!-- end tr -->
                        <tr>
                          <td>
                            <router-link
                              to="/ecommerce/order-details"
                              class="fw-medium link-primary"
                              >#VZ2108
                            </router-link>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="flex-shrink-0 me-2">
                                <img
                                  src="@/assets/images/users/avatar-4.jpg"
                                  alt=""
                                  class="avatar-xs rounded-circle shadow"
                                />
                              </div>
                              <div class="flex-grow-1">Prezy Mark</div>
                            </div>
                          </td>
                          <td>Furniture</td>
                          <td>
                            <span class="text-success">$199.00</span>
                          </td>
                          <td>Syntyce Solutions</td>
                          <td>
                            <span class="badge badge-soft-danger">Unpaid</span>
                          </td>
                          <td>
                            <h5 class="fs-14 fw-medium mb-0">
                              4.3<span class="text-muted fs-11 ms-1"
                                >(47 votes)</span
                              >
                            </h5>
                          </td>
                        </tr>
                        <!-- end tr -->
                        <tr>
                          <td>
                            <router-link
                              to="/ecommerce/order-details"
                              class="fw-medium link-primary"
                              >#VZ2107
                            </router-link>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="flex-shrink-0 me-2">
                                <img
                                  src="@/assets/images/users/avatar-6.jpg"
                                  alt=""
                                  class="avatar-xs rounded-circle shadow"
                                />
                              </div>
                              <div class="flex-grow-1">Vihan Hudda</div>
                            </div>
                          </td>
                          <td>Bags and Wallets</td>
                          <td>
                            <span class="text-success">$330.00</span>
                          </td>
                          <td>iTest Factory</td>
                          <td>
                            <span class="badge badge-soft-success">Paid</span>
                          </td>
                          <td>
                            <h5 class="fs-14 fw-medium mb-0">
                              4.7<span class="text-muted fs-11 ms-1"
                                >(161 votes)</span
                              >
                            </h5>
                          </td>
                        </tr>
                        <!-- end tr -->
                      </tbody>
                      <!-- end tbody -->
                    </table>
                    <!-- end table -->
                  </div>
                </div>
              </div>
              <!-- .card-->
            </div>
            <!-- .col-->
          </div>
          <!-- end row-->

          <!-- <div class="row">
            <div class="col-xl-12">
              <Revenue :transactions="dashboardData.charts.transactions" />
            </div>
          </div> -->
        </div>
        <!-- end .h-100-->
      </div>
      <!-- end col -->
    </div>
  </Layout>
</template>
